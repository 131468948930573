import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import dynamic from "next/dynamic";

const PageTitle = dynamic(() => import('../Reusables/PageTitle'));
const HomeDescription = () => {

    const { template_page_content } = useSelector((state) => state.globalReducer);
    const page_content = template_page_content['home'];
    
    return (
        <>
   
                <PageTitle sx={{ color: '#616467' }} component="h1" className="text-center uppercase">{page_content.section_1.title}</PageTitle>
                
                <Typography 
                    sx={{
                        paddingLeft: {xs: '1rem', lg: 0},
                        paddingRight: {xs: '1rem', lg: 0},
                        fontWeight: 400
                    }} 
                    className="hire_furniture_p f-size_2 pt-0 font-regular"
                >
                    {page_content.section_1.content}
                </Typography>

        </>

    )
}

export default HomeDescription